export default {
  MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
      REGION: "us-east-1",
      BUCKET: "jtb-notes-attachments"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://q2hpf39lr4.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_LXyw8pgaC",
      APP_CLIENT_ID: "4lklgcppnvhccvmf6o8meu9068",
      IDENTITY_POOL_ID: "us-east-1:bfa40d2a-0618-48c1-b60d-781493a4aee2"
    }
  };
  